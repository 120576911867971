const items = document.querySelectorAll(".accordion button");

function toggleAccordion() {
  const itemToggle = this.getAttribute('aria-expanded');
  
  for (i = 0; i < items.length; i++) {
    items[i].setAttribute('aria-expanded', 'false');
  }
  
  if (itemToggle == 'false') {
    this.setAttribute('aria-expanded', 'true');
  }
}

items.forEach(item => item.addEventListener('click', toggleAccordion));


// SCROLL TO LOGIC
$(document).ready(function() {
  $(window).scroll(function() {
    var scrollPos = $(window).scrollTop();
    
    var blossom = $("#blossom").offset().top;
    var boilers = $("#boilers").offset().top;
    var howItWorks = $("#how_it_works").offset().top - 250;
    var faqs = $("#faqs").offset().top - 180;

    if (scrollPos >= blossom && scrollPos < boilers) {
      $("#blossom-link").addClass("active");
      $("#boilers-link").removeClass("active");
      $("#how-it-works-link").removeClass("active");
      $("#faqs-link").removeClass("active");
    } else {
      $("#blossom-link").removeClass("active");
    }

    if (scrollPos >= boilers && scrollPos < howItWorks) {
      $("#boilers-link").addClass("active");
      $("#blossom-link").removeClass("active");
      $("#how-it-works-link").removeClass("active");
      $("#faqs-link").removeClass("active");
    } else {
      $("#boilers-link").removeClass("active");
    }
    
    if (scrollPos >= howItWorks && scrollPos < faqs) {
      $("#how-it-works-link").addClass("active");
      $("#blossom-link").removeClass("active");
      $("#boilers-link").removeClass("active");
      $("#faqs-link").removeClass("active");
    } else {
      $("#how-it-works-link").removeClass("active");
    }

    if (scrollPos >= faqs) {
      $("#faqs-link").addClass("active");
      $("#blossom-link").removeClass("active");
      $("#boilers-link").removeClass("active");
      $("#how-it-works-link").removeClass("active");
    } else {
      $("#faqs-link").removeClass("active");
    }

  });
});


$("#blossom-link").click(function(){
  $('html, body').animate({
    scrollTop: $("#blossom").offset().top - 70 // Scroll to 5px above the top of .blpost_frame
  }, 500)
});

$("#boilers-link").click(function(){
  $('html, body').animate({
    scrollTop: $("#boilers").offset().top + 170
  }, 500)
});

$("#how-it-works-link").click(function(){
  $('html, body').animate({
    scrollTop: $("#how_it_works").offset().top
  }, 500)
});

$("#faqs-link").click(function(){
  $('html, body').animate({
    scrollTop: $("#faqs").offset().top - 100
  }, 500)
});

$('.trigger-contact').click(function(){
  $('.modal-contact').addClass('show-modal');
  $('body').addClass('modal-active');
});

$('.trigger-privacy').click(function(){
  $('.modal-privacy').addClass('show-modal');
  $('body').addClass('modal-active');
});

$('.trigger-terms').click(function(){
  $('.modal-terms').addClass('show-modal');
  $('body').addClass('modal-active');
});

$('.close-button').click(function(){
  $('.modal').removeClass('show-modal');
  $('body').removeClass('modal-active');
});


function clearForm() {
  document.getElementById("contact-form").reset();
}